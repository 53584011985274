import { Box, Button, Container, Input, Table, TableBody, TableCell, TableHead, TableRow, TextField } from "@mui/material";
import swal from "sweetalert";
import axios from "axios";
import { DashboardLayout } from "../../components/dashboard-layout";
import { ThemeProvider } from "@mui/material/styles";
import "./account.css";
import { theme } from "../../theme";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

function Employee() {
    function formatDate(m) {
        new Date(m);
        const dateString =
            m.getUTCFullYear() +
            "/" +
            ("0" + (m.getMonth() + 1)).slice(-2) +
            "/" +
            ("0" + m.getDate()).slice(-2) +
            "  " +
            ("0" + m.getHours()).slice(-2) +
            ":" +
            ("0" + m.getMinutes()).slice(-2);
        return dateString;
    }
    const [users, setUser] = useState(null);
    const [load, setLoad] = useState(false);
    const [searched, setSearched] = useState("");
    const navigate = useNavigate();
    const [profile, setProfile] = useState(null);
    const [isShow, setShow] = useState(false);
    const [ls, setLs] = useState(null);

    axios.interceptors.request.use(
        (config) => {
            const token = localStorage.getItem("user");
            if (token) {
                config.headers["Authorization"] = `Bearer ${token}`;
            }
            return config;
        },
        (error) => {
            return Promise.reject(error);
        }
    );
    const handleSubmit = (e) => {
        e.preventDefault();
        const formData = {
            username: e.target.username.value,
            password: e.target.password.value
        };

        axios
            .post(`https://server.tuthiensenhong.com/auth/createEmployee`, formData)
            .then((res) => {
                setLoad(true);
                swal("Thành công", "Thêm mới nhân viên thành công!", "success");
            })
            .catch((res) => setLoad(true));
    };
    useEffect(() => {
        if (load == false) {
            axios.get(`https://server.tuthiensenhong.com/auth/getUser`, {}).then((res) => {
                setProfile(res.data.data.username);
            });
            axios
                .get(`https://server.tuthiensenhong.com/auth/getEmployee`, {})
                .then((res) => {
                    if (res.data.data) localStorage.setItem("data", JSON.stringify(res.data.data));
                    setUser(res.data.data);
                    setLoad(true);
                })
                .then((res) => setLoad(true));
        }
    }, [load]);
    const requestSearch = (searchedVal) => {
        setSearched(searchedVal);

        if (searchedVal !== "") {
            const filteredRows = JSON.parse(localStorage.getItem("data")).filter((row) => {
                const a = row?.username.toString().toLowerCase().includes(searchedVal.toString().toLowerCase());
                const b = Number(row.iduser).toString().toLowerCase().includes(searchedVal.toString().toLowerCase());
                return a || b;
            });
            setUser(filteredRows);
        } else {
            setUser(JSON.parse(localStorage.getItem("data")));
        }
    };
    const handleSubmitLs = (e) => {
        e.preventDefault();
        const formData = {
            id: ls._id,
            permission: {
                set: Number(e.target.set.value),
                money: Number(e.target.money.value),
                edit: Number(e.target.edit.value)
            }
        };
        axios
            .post(`https://server.tuthiensenhong.com/auth/permission`, formData)
            .then((res) => {
                setShow(false);
                swal("Cập nhật thành công").then((value) => {
                    window.location.reload();
                });
            })
            .catch((err) => {
                setShow(false);
                swal("Có lỗi vui lòng thử lại!");
            });
    };
    return (
        <>
            <ThemeProvider theme={theme}>
                <DashboardLayout>
                    {profile == "administrator" ? (
                        <>
                            <Box
                                component="main"
                                sx={{
                                    flexGrow: 1,
                                    py: 8
                                }}>
                                <Container sx={{ justifyContent: "center" }} maxWidth={false}>
                                    <div style={{ fontSize: "25px", fontWeight: 700 }}>Tạo tài khoản nhân viên</div>
                                    <div style={{ display: "flex", justifyContent: "center", marginTop: "20px", marginLeft: "50px" }}>
                                        <form onSubmit={handleSubmit}>
                                            <div>
                                                <div style={{ width: "400px", marginBottom: "20px" }}>
                                                    <label>Tài khoản</label>
                                                    <input type="username" name="username" id="username" className="input_setting" />
                                                </div>
                                            </div>
                                            <div>
                                                <div style={{ width: "400px", marginBottom: "20px" }}>
                                                    <label>Mật khẩu</label>
                                                    <input type="password" name="password" id="password" className="input_setting" />
                                                </div>
                                            </div>
                                            <button className="btn_setting">Xác nhận</button>
                                        </form>
                                    </div>
                                </Container>
                            </Box>
                            <Box
                                component="main"
                                sx={{
                                    flexGrow: 1,
                                    py: 1
                                }}>
                                <Container maxWidth={false}>
                                    <div className="container_set">Danh sách nhân viên</div>
                                    <div className="form_set">
                                        <Box sx={{ minWidth: 1000 }}>
                                            {/*<TextField
                                        value={searched}
                                        onChange={(searchVal) => requestSearch(searchVal.target.value)}
                                        placeholder="Tìm kiếm"
                                        sx={{ marginBottom: "5px", paddingRight: "700px" }}
                                    />*/}
                                            <Table>
                                                <TableHead>
                                                    <TableRow>
                                                        <TableCell sx={{ padding: "10px" }}>ID User</TableCell>
                                                        <TableCell sx={{ padding: "10px" }}>Username</TableCell>
                                                        {/*<TableCell sx={{ padding: "10px" }}>Mã giới thiệu</TableCell>*/}
                                                        <TableCell sx={{ padding: "10px" }}>Ngày tạo</TableCell>
                                                        <TableCell sx={{ padding: "10px", textAlign: "center" }}>Thao tác</TableCell>
                                                    </TableRow>
                                                </TableHead>
                                                <TableBody>
                                                    {users &&
                                                        users.map((item) => (
                                                            <>
                                                                <TableRow>
                                                                    <TableCell sx={{ fontWeight: "600", padding: "10px" }}>{item.iduser}</TableCell>
                                                                    <TableCell sx={{ fontWeight: "600", padding: "10px" }}>{item.username}</TableCell>
                                                                    {/*<TableCell sx={{ fontWeight: "600", padding: "10px" }}>{item.code}</TableCell>*/}
                                                                    <TableCell sx={{ fontWeight: "600", padding: "10px" }}>{formatDate(new Date(item.createdAt))}</TableCell>
                                                                    <TableCell sx={{ fontWeight: "600", display: "flex", alignItems: "center", justifyContent: "center" }}>
                                                                        {item.isLock == false ? (
                                                                            <Button
                                                                                onClick={() => {
                                                                                    axios
                                                                                        .post(`https://server.tuthiensenhong.com/auth/lockkey`, {
                                                                                            id: item._id,

                                                                                            isLock: true
                                                                                        })
                                                                                        .then((res) => {
                                                                                            setLoad(false);
                                                                                        });
                                                                                }}>
                                                                                Khóa
                                                                            </Button>
                                                                        ) : (
                                                                            <Button
                                                                                onClick={() => {
                                                                                    axios
                                                                                        .post(`https://server.tuthiensenhong.com/auth/lockkey`, {
                                                                                            id: item._id,

                                                                                            isLock: false
                                                                                        })
                                                                                        .then((res) => {
                                                                                            setLoad(false);
                                                                                        });
                                                                                }}>
                                                                                Mở khóa
                                                                            </Button>
                                                                        )}
                                                                        <Button
                                                                            onClick={() => {
                                                                                setShow(true);
                                                                                setLs(item);
                                                                            }}>
                                                                            Phân quyền
                                                                        </Button>
                                                                    </TableCell>
                                                                </TableRow>
                                                            </>
                                                        ))}
                                                </TableBody>
                                            </Table>
                                        </Box>
                                    </div>
                                </Container>
                            </Box>
                            {isShow === true ? (
                                <>
                                    <div className="modal">
                                        <div className="modaloverlay">
                                            <i className="ti-close closelogin"></i>
                                        </div>
                                        <div className="modalbody">
                                            <form onSubmit={handleSubmitLs}>
                                                <div className="modalinner">
                                                    <div className="modalheader"> Phân quyền </div>

                                                    <div className="modalform">
                                                        <div className="modalformgroup d-flex" style={{ padding: "9px" }}>
                                                            <div>Nhân viên: </div>
                                                            <div>
                                                                <b>{ls?.username}</b>
                                                            </div>
                                                        </div>
                                                        <div style={{ padding: "9px" }} className="modalformgroup d-flex">
                                                            <div>Nạp rút, cộng trừ, thưởng </div>
                                                            <TextField name="money" defaultValue={ls?.permission?.money} sx={{ width: "100%" }} type="number" />
                                                        </div>
                                                        <div style={{ padding: "9px" }} className="modalformgroup d-flex">
                                                            <div>Set kết quả, sửa lịch sử </div>
                                                            <TextField name="set" defaultValue={ls?.permission?.set} sx={{ width: "100%" }} type="number" />
                                                        </div>
                                                        <div style={{ padding: "9px" }} className="modalformgroup d-flex">
                                                            <div>Chỉnh sửa người chơi </div>
                                                            <TextField name="edit" defaultValue={ls?.permission?.edit} sx={{ width: "100%" }} type="number" />
                                                        </div>
                                                    </div>

                                                    <div className="item_btn_form">
                                                        <div className="modalformcontrols">
                                                            <Button type="submit">XÁC NHẬN</Button>
                                                        </div>
                                                        <div className="modalformcontrols">
                                                            <Button onClick={() => setShow(false)}>ĐÓNG</Button>
                                                        </div>
                                                    </div>
                                                </div>
                                            </form>
                                        </div>
                                    </div>
                                </>
                            ) : null}
                        </>
                    ) : (
                        <div>Bạn không có quyền truy cập</div>
                    )}
                </DashboardLayout>
            </ThemeProvider>
        </>
    );
}

export default Employee;
